<template>
  <v-app-bar fixed color="navbar" app>
    <v-app-bar-nav-icon @click.stop="$emit('hide', !show)"></v-app-bar-nav-icon>
    <!-- <v-toolbar-title>{{ title || 'Dashboard' }}</v-toolbar-title> -->

    <v-spacer></v-spacer>
    <div class="px-4">
      <v-spacer></v-spacer>
      <!-- <v-btn icon color="primary" @click="openDialog()">
        <v-icon>info</v-icon>
      </v-btn> -->

      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn text color v-on="on">
            <flag :iso="currentFlag" v-bind:squared="false" />
            <v-icon right color="primary">mdi-menu-down</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item
            v-for="(entry, index) in languagess"
            :key="`${index}010`"
            @click="changeLocale(entry)"
          >
            <v-list-item-title>
              <flag :iso="entry.flag" v-bind:squared="false" />
              &nbsp;{{ entry.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-menu
        bottom
        origin="center center"
        transition="scale-transition"
        offset-y
        v-if="currentUser"
      >
        <template v-slot:activator="{ on }">
          <v-btn icon small v-on="on" color="primary">
            <div>
              <v-badge
                color="red"
                :content="notifications"
                bordered
                overlap
                left
                v-if="notifications"
              >
                <v-icon color="primary"> mdi-bell </v-icon>
              </v-badge>
              <v-icon color="primary" v-else> mdi-bell </v-icon>
            </div>
          </v-btn>
        </template>

        <v-card class="mx-auto" max-width="500">
          <v-list two-line>
            <v-list-item>
              <v-list-item-action>
                <v-icon class="primary--text">mdi-message-text</v-icon>
              </v-list-item-action>
              <v-list-item-title>{{ $t("messages") }} </v-list-item-title>
              <v-list-item-action> ({{ notifications }}) </v-list-item-action>
            </v-list-item>
            <v-divider class="mx-3" style="margin-top: -4.5%"></v-divider>
            <v-list-item-group v-model="selected" active-class="primary--text">
              <template v-for="(skeleton, x) in 3">
                <v-skeleton-loader :key="x" type="list-item-avatar" v-if="!loadMessages">
                </v-skeleton-loader>
              </template>

              <template v-for="(item, index) in chats">
                <!-- <pre :key="index">
              {{item}}
            </pre> -->
                <v-list-item
                  :key="item.name"
                  v-if="
                    (item.flag === 'Suport' && countReads(item.messages) > 0) ||
                    item.flag === 'Chat'
                  "
                  @click="item.flag === 'Chat' ? initChat(item) : initSuport()"
                >
                  <!-- <template> -->
                  <template v-slot:default="{ active }">
                    <v-list-item-avatar>
                      <v-badge
                        bordered
                        bottom
                        color="primary"
                        dot
                        offset-x="15"
                        offset-y="11"
                      >
                        <v-avatar
                          size="35"
                          v-if="item.messages[0].to.id != currentUser.id"
                        >
                          <v-img
                            :src="
                              item.flag === 'Suport'
                                ? item.messages[0].to.photo
                                  ? `${apiUrl}/images/partner/${item.messages[0].to.photo}`
                                  : '/profile.png'
                                : item.messages[0].to.photo
                                ? item.messages[0].to.type === 'entity'
                                  ? `${apiUrl}/images/user/${item.messages[0].to.photo}`
                                  : `${apiUrl}/images/partner/${item.messages[0].to.photo}`
                                : '/profile.png'
                            "
                          ></v-img>
                        </v-avatar>
                        <v-avatar size="35" v-else>
                          <v-img
                            :src="
                              item.messages[0].author.photo
                                ? `${apiUrl}/images/user/${item.messages[0].author.photo}`
                                : '/profile.png'
                            "
                          ></v-img>
                        </v-avatar>
                      </v-badge>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="
                          item.flag === 'Suport'
                            ? item.messages[0].to.name === currentUser.name
                              ? 'Suporte WhireLab'
                              : 'Suporte WhireLab'
                            : item.messages[0].to.name === currentUser.name
                            ? getUserName(item.messages[0].author.name)
                            : getUserName(item.messages[0].to.name)
                        "
                      ></v-list-item-title>

                      <v-list-item-subtitle
                        class="text--primary"
                        v-text="item.messages[item.messages.length - 1].body"
                      ></v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-badge
                        color="red"
                        :content="countReads(item.messages)"
                        bordered
                        overlap
                        v-if="countReads(item.messages) > 0"
                      >
                        <v-icon v-if="!active" color="grey"> mdi-chat </v-icon>

                        <v-icon v-else color="grey"> mdi-chat </v-icon>
                      </v-badge>
                      <v-btn block outlined color="primary" v-else> start </v-btn>
                    </v-list-item-action>
                  </template>
                </v-list-item>
                <v-divider v-if="index < chats.length - 1" :key="index"></v-divider>
              </template>

              <!-- <pre>
  {{suportChats[0].messages[suportChats[0].messages.length-1]}}
</pre> -->

              <template v-for="(item, index) in suportChats">
                <!-- <pre :key="index">
              {{item.messages[item.messages.length-1].body}}
            </pre> -->
                <v-list-item
                  :key="item.name"
                  v-if="countReadsSuport(item.messages) > 0"
                  @click="initSuport()"
                >
                  <!-- <template> -->
                  <template v-slot:default="{ active }">
                    <v-list-item-avatar>
                      <v-badge
                        bordered
                        bottom
                        color="primary"
                        dot
                        offset-x="15"
                        offset-y="11"
                      >
                        <v-avatar size="35">
                          <v-img
                            :src="
                              SuportPhoto
                                ? `${apiUrl}/images/user/${SuportPhoto}`
                                : '/profile.png'
                            "
                          ></v-img>
                        </v-avatar>
                      </v-badge>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-text="'Suporte WhireLab'"></v-list-item-title>

                      <v-list-item-subtitle
                        class="text--primary"
                        v-text="item.messages[item.messages.length - 1].body"
                      ></v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-badge
                        color="red"
                        :content="countReadsSuport(item.messages)"
                        bordered
                        overlap
                        v-if="countReadsSuport(item.messages) > 0"
                      >
                        <v-icon v-if="!active" color="grey"> mdi-chat </v-icon>

                        <v-icon v-else color="grey"> mdi-chat </v-icon>
                      </v-badge>
                      <v-btn block outlined color="primary" v-else> start </v-btn>
                    </v-list-item-action>
                  </template>
                  -->
                </v-list-item>
                <v-divider v-if="index < chats.length - 1" :key="index"></v-divider>
              </template>
            </v-list-item-group>
            <v-list-item>
              <v-list-item-action>
                <v-icon class="primary--text">mdi-account-multiple</v-icon>
              </v-list-item-action>
              <v-list-item-title>Candidaturas</v-list-item-title>
              <v-list-item-action> ({{ 0 }}) </v-list-item-action>
            </v-list-item>
            <v-divider class="mx-3" style="margin-top: -4.5%"></v-divider>
          </v-list>
        </v-card>
      </v-menu>

      <v-menu
        bottom
        origin="center center"
        transition="scale-transition"
        offset-y
        v-if="currentUser"
        width="400px"
      >
        <template v-slot:activator="{ on }">
          <v-btn icon small v-on="on" color="primary">
            <v-avatar size="22" item>
              <img
                v-if="currentUser.photo"
                :src="`${apiUrl}/images/user/${currentUser.photo}`"
              />
              <v-icon v-else class="primary--text">mdi-account-circle</v-icon>
            </v-avatar>
          </v-btn>
        </template>

        <v-card class="mx-auto">
          <v-list two-line>
            <v-list-item>
              <v-list-item-avatar>
                <img
                  :src="
                    currentUser.photo
                      ? `${apiUrl}/images/user/${currentUser.photo}`
                      : `/avatar.png`
                  "
                />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ currentUser.name }}
                </v-list-item-title>
                <v-list-item-subtitle class="caption">
                  {{ currentUser.email }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-list>
            <v-list-item router to="/dashboard/entity/user/profile">
              <v-list-item-action>
                <v-icon class="primary--text">mdi-account-circle</v-icon>
              </v-list-item-action>
              <v-list-item-title>{{ $t("form_candidate_profile") }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click.prevent="logout">
              <v-list-item-action>
                <v-icon class="primary--text">mdi-logout-variant</v-icon>
              </v-list-item-action>
              <v-list-item-title>{{ $t("logout") }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>

      <v-menu
        v-if="accessLevel(this.currentUser, 'setting_list')"
        bottom
        origin="center center"
        transition="scale-transition"
        offset-y
      >
        <template v-slot:activator="{ on }">
          <v-btn icon small>
            <v-icon v-on="on" color="primary">settings</v-icon>
          </v-btn>
        </template>

        <v-list>
          <template v-for="(setting, i) in settings">
            <v-list-item
              v-if="setting.show"
              :key="i"
              route
              :to="setting.route"
              :exact="setting.exact"
            >
              <v-list-item-action>
                <v-icon color="primary">{{ setting.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-title>{{ setting.title }}</v-list-item-title>
            </v-list-item>
          </template>
        </v-list>
      </v-menu>
    </div>
  </v-app-bar>
</template>

<script>
import { API_URL } from "@/api";
import { GC_AUTH_TOKEN, GC_USER_ID } from "@/constants/settings";
import { USER_QUERY } from "../graphql/Query.gql";
import { GET_TRAINING_AREAS } from "../graphql/Query.gql";
import { mapActions, mapGetters } from "vuex";
import {
  CHATS_ROOM_QUERY,
  CHATS_QUERY_OUT_LIST,
  CHATS_ROOM_QUERY_UPD,
} from "./../modules/chat/graphql/Query";
import {
  MESSAGE_SENT_SUBSCRIPTION,
  UPDATED_COUNT_CHAT,
} from "./../modules/chat/graphql/subscription";
import { SEND_STATUS } from "../graphql/Mutation.gql";
import accessLevelMixins from "@/mixins/access-level";
import Vue2Filters from "vue2-filters";
export default {
  name: "AppToolbar",
  props: {
    show: Boolean,
  },
  mixins: [accessLevelMixins, Vue2Filters.mixin],
  model: {
    prop: "show",
    event: "hide",
  },
  data: () => ({
    apiUrl: API_URL,
    currentFlag: "pt",
    showLogoutDialog: false,
    user: null,
    notifications: 0,
    notificationsSuporte: 0,
    chats: [],
    suportChats: [],
    SuportName: "",
    SuportPhoto: "",
    selected: [2],
    loadMessages: false,
    flag: true,
  }),
  apollo: {
    user: {
      query: USER_QUERY,
      fetchPolicy: "no-cache",
    },
    getTrainingAreas: {
      query: GET_TRAINING_AREAS,
      result({ data }) {
        this.setTrainingAreas(data.getTrainingAreas);
      },
    },
    recivedChats: {
      query: CHATS_ROOM_QUERY,
      fetchPolicy: "no-cache",
      result({ data }) {
        if (data.recivedChats) {
          this.loadMessages = true;
          console.log("recivedChats toolBar", data.recivedChats);
          data.recivedChats.forEach((chat) => {
            // console.log('to',chat.messages[chat.messages.length-1]);
            // console.log('author',chat.messages[chat.messages.length-1].author.id);
            if (
              chat.messages[chat.messages.length - 1].to.id !=
              chat.messages[chat.messages.length - 1].author.id
            ) {
              this.chats.push(chat);
            }
          });
          // this.chats = data.recivedChats;
          this.chats.forEach((element) => {
            this.notifications += this.countReads(element.messages);
          });
        }
      },
    },
    chatsWL: {
      query: CHATS_QUERY_OUT_LIST,
      variables() {
        return { id: this.currentUser.id };
      },
      client: "apolloClientWhirelab",
      fetchPolicy: "no-cache",
      result({ data }) {
        if (data.chatsWL) {
          this.loadMessages = true;
          console.log("recivedChats whirelab", data.chatsWL);
          this.suportChats = data.chatsWL;

          this.suportChats.forEach((element) => {
            console.log("element", element.messages);
            this.notificationsSuporte += this.countReadsSuport(element.messages);
            this.notifications += this.notificationsSuporte;
          });
          this.SuportName = this.suportChats[0].messages[
            this.suportChats[0].messages.length - 1
          ].author.name;
          this.SuportPhoto = this.suportChats[0].messages[
            this.suportChats[0].messages.length - 1
          ].author.photo;
          console.log("this.SuportName", this.SuportName);
        }
      },
    },
    $subscribe: {
      message: {
        query: MESSAGE_SENT_SUBSCRIPTION,
        result({ data }) {
          console.log("=>>>>>", data.addChat);
          if (
            data.addChat.messages.to.id === data.addChat.messages.author.id &&
            data.addChat.flag === "Suport"
          ) {
            return;
          } else if (data.addChat.messages.to.id === this.currentUser.id) {
            this.playSound();
            this.getMessage();
          }
        },
      },

      messageWL: {
        query: MESSAGE_SENT_SUBSCRIPTION,
        client: "apolloClientWhirelab",
        result({ data }) {
          console.log("addChat", data.addChat.messages);
          if (data.addChat.name == this.currentUser.id) {
            if (data.addChat.messages.to.id === this.currentUser.id) {
              if (data.addChat.messages.to.id != data.addChat.messages.author.id) {
                this.playSound();
              }

              this.getMessageSuport();
              console.log(data.addChat.messages.to.id, data.addChat.messages.author.id);
              // var index = this.suportChats.findIndex(
              //   (u) => u.name == data.addChat.name
              // );
              // if (index > -1) {
              //   this.suportChats[index].messages.push(data.addChat.messages);
              //   this.notifications = 0;
              //   this.suportChats.forEach((element) => {
              //     this.notifications += this.countReads(element.messages);
              //   });
              // }
            }
          }
        },
      },
      updateCountChat: {
        query: UPDATED_COUNT_CHAT,
        result({ data }) {
          var index = this.chats.findIndex((u) => u.room == data.updateCountChat.room);
          if (index > -1) {
            this.chats[index].messages = data.updateCountChat.messages;
            this.notifications = 0;
            this.chats.forEach((element) => {
              this.notifications += this.countReads(element.messages);
            });
          }
        },
      },
      updateCountChatWL: {
        query: UPDATED_COUNT_CHAT,
        client: "apolloClientWhirelab",
        result({ data }) {
          if (data) {
            this.getMessageSuport();
          }
        },
      },
    },
  },
  watch: {
    user: function (val) {
      this.setUserState(val);
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
    languagess: function () {
      return [
        { flag: "gb", language: "en", title: "English" },
        // { flag: "fr", language: "fr", title: "Français" },
        { flag: "pt", language: "pt", title: "Português" },
      ];
    },
    settings: function () {
      return [
        {
          icon: "mdi-domain",
          title: this.$t("institutionprofile"),
          route: "/dashboard/entity/profile",
          exact: true,
          show: this.accessLevel(this.currentUser, "setting_compony_profile_edit"),
        },
        {
          icon: "mdi-key-variant",
          title: this.$t("AccessLevel"),
          route: "/dashboard/entity/roles",
          exact: true,
          show: this.accessLevel(this.currentUser, "setting_access_level"),
        },
        {
          icon: "mdi-key-variant",
          title: this.$t("academic_education"),
          route: "/dashboard/entity/training-area",
          exact: true,
          // show: this.accessLevel(this.currentUser, "setting_access_level"),
          show: true,
        },
        {
          icon: "mdi-account-group",
          title: this.$t("user"),
          route: "/dashboard/entity/users",
          exact: true,
          show: this.accessLevel(this.currentUser, "setting_user"),
        },
      ];
    },
    userId() {
      return this.$root.$data.userId;
    },
  },

  created() {
    window.addEventListener("beforeunload", this.leaving);

    if (localStorage.getItem("lang") != null) {
      this.$i18n.locale = localStorage.getItem("lang");
      this.currentFlag = localStorage.getItem("flag");
    } else {
      this.$i18n.locale = "pt";
      this.currentFlag = "pt";
    }
  },
  methods: {
    ...mapActions({
      setUserState: "auth/setUser",
      setTrainingAreas: "library/setTrainingAreas",
    }),
    async playSound() {
      var audio = new Audio("/whirelabRing.wav");
      audio.muted = false;
      audio.autoplay = true;
      audio.load();
      var playPromise = audio.play();
      console.log(playPromise);
      if (playPromise !== undefined) {
        await playPromise
          .then((_) => {
            console.log("play", _);
            audio.play();
          })
          .catch((error) => {
            console.log("err", error);
          });
      }
    },
    getUserName(name) {
      name = name.split(" ");
      return name[0];
    },
    changeLocale(locale) {
      this.$i18n.locale = locale.language;
      this.currentFlag = locale.flag;

      localStorage.setItem("lang", locale.language);
      localStorage.setItem("flag", locale.flag);

      console.log(locale, localStorage.getItem("lang"));
      localStorage.setItem("localeCV",locale.language)
      this.backOnline();
    },
    async loadUser() {
      await this.$apollo.mutate({});
    },
    logout() {
      this.logoutPropreties();
      this.leaving();
    },
    logoutPropreties() {
      localStorage.removeItem(GC_USER_ID);
      localStorage.removeItem(GC_AUTH_TOKEN);
      this.$root.$data.userId = localStorage.getItem(GC_USER_ID);
      this.$router.push("/login");
      // this.leaving();
    },
    countReads(messages) {
      // console.log("count",messages);
      return messages.filter((m) => {
        if (m.to && m.read === "1" && m.to.id === this.currentUser.id) {
          return m;
        }
      }).length;
    },
    countReadsSuport(messages) {
      // console.log("count",messages);
      return messages.filter((m) => {
        if (m.to == null) {
          // console.log('mostra',m);
          if (m.read === "1" && m.author.name != this.currentUser.name) {
            return m;
          }
        }
      }).length;
    },
    async initChat(valor) {
      // console.log("valor", valor);
      if (valor.messages[0].to.id === this.currentUser.id) {
        valor = [{ ...valor, to: valor.messages[0].author.id }];
      } else if (valor.messages[0].author.id === this.currentUser.id) {
        valor = [{ ...valor, to: valor.messages[0].to.id }];
      }
      this.$emit("initChat", valor[0]);
    },
    initSuport() {
      this.$emit("initChatSuport");
    },
    async leaving() {
      await this.$apollo.mutate({
        mutation: SEND_STATUS,
        variables: {
          user_id: this.currentUser.id,
          description: "Offline",
          locale: this.$i18n.locale,
        },
      });
      this.leavingWl();
    },
    async leavingWl() {
      await this.$apollo.mutate({
        mutation: SEND_STATUS,
        client: "apolloClientWhirelab",
        variables: {
          user_id: this.currentUser.id,
          description: "Offline",
          locale: this.$i18n.locale,
        },
      });
    },
    async backOnline() {
      await this.$apollo.mutate({
        mutation: SEND_STATUS,
        variables: {
          user_id: this.currentUser.id,
          description: "Online",
          locale: this.$i18n.locale,
        },
      });

      this.backOnlineWl();
    },
    async backOnlineWl() {
      await this.$apollo.mutate({
        mutation: SEND_STATUS,
        client: "apolloClientWhirelab",
        variables: {
          user_id: this.currentUser.id,
          description: "Online",
          locale: this.$i18n.locale,
        },
      });
    },
    async getMessage() {
      await this.$apollo
        .query({
          query: CHATS_ROOM_QUERY,
          fetchPolicy: "no-cache",
        })
        .then((res) => {
          if (res.data.recivedChats) {
            this.chats = res.data.recivedChats;

            this.notificationsSuporte = 0;
            this.notifications = 0;
            this.chats.forEach((element) => {
              this.notifications += this.countReads(element.messages);
            });
            this.suportChats.forEach((element) => {
              this.notificationsSuporte += this.countReadsSuport(element.messages);
            });
            this.notifications += this.notificationsSuporte;
            console.log("ordered", res.data.recivedChats);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getMessageSuport() {
      await this.$apollo
        .query({
          query: CHATS_ROOM_QUERY_UPD,
          client: "apolloClientWhirelab",
          fetchPolicy: "no-cache",
        })
        .then((res) => {
          if (res.data) {
            this.suportChats = res.data.getReciveMessageSuport_K.filter(
              (element) => element.room === this.currentUser.id
            );
            this.notificationsSuporte = 0;
            this.notifications = 0;
            this.chats.forEach((element) => {
              this.notifications += this.countReads(element.messages);
            });
            this.suportChats.forEach((element) => {
              this.notificationsSuporte += this.countReadsSuport(element.messages);
            });
            this.notifications += this.notificationsSuporte;
            this.SuportName = this.suportChats[0].messages[
              this.suportChats[0].messages.length - 1
            ].author.name;
            this.SuportPhoto = this.suportChats[0].messages[
              this.suportChats[0].messages.length - 1
            ].author.photo;
            console.log("upd", this.suportChats);
          }
          return this.suportChats;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    setTimeout(() => {
      this.backOnline();
      this.$emit("hide", false);
    }, 1000);
  },
};
</script>
