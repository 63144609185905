<template>
  <div v-click-outside="onClickOutside">
    <v-card class="sc-chat-window" border>
      <v-toolbar color="primary" light dark dense>
        <v-avatar size="36">
          <img :src="`/avatar-whire.png`" />
        </v-avatar>
        <v-toolbar-title class="mx-2">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title style="text-transform: uppercase; font-size:10pt;"
                >{{$t('Personalassistant')}}</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-row class="d-flex flex-row justify-end mt-2 px-3">
        <v-card-text
          class="pt-4 welcomeMessage"
          style=" color:#fff; margin-top:-10px"
        >
          <v-container>
            <v-col cols="12">
              <v-container>
                <p v-html="$t('chatwelcome')">
                  {{$t('chatwelcome')}}
                </p>
              </v-container>
            </v-col>
          </v-container>
        </v-card-text>
      </v-row>
      <v-row class="d-flex flex-row justify-end align-center mt-5 px-3">
        <v-container>
          <v-container>
            <v-btn block outlined color="primary" @click="initList"> {{$t('internalchat')}} </v-btn><br />
            <v-btn block outlined color="primary" @click="initSuport">{{$t('HelpCenter')}}</v-btn>
          </v-container>
        </v-container>
      </v-row>
      <div ref="scrollList" class="sc-message-list"></div>
      <v-row class="d-flex flex-row justify-end align-center mt-5 px-3">
        <v-container>
          <v-container>
            <!-- <v-btn block text  style="background: #FFF4E4; color:#000;"> invite candidate </v-btn> -->
          </v-container>
        </v-container>
      </v-row>
    </v-card>
  </div>
</template>

<script>
export default {
  data: () => ({}),
   methods: {
    initList() {
      this.$emit("initList");
    },
    initSuport() {
      this.$emit("initSuport");
    },
    onClickOutside(event, el) {
      console.log(event, el);
      this.$emit('init');
    },
  },
};
</script>
<style scoped src="@/assets/css/chat_css/chatWelcome.css"></style>
