<template>
  <div v-click-outside="onClickOutside">
    <v-card class="sc-chat-window" border>
      <v-toolbar color="primary" light dark dense>
        <v-avatar size="36">
          <img :src="`/avatar-whire.png`" />
        </v-avatar>
        <v-toolbar-title class="mx-2">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title
                style="text-transform: uppercase; font-size: 10pt"
              >
                {{ $t("Personalassistant") }}</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-row class="d-flex flex-row justify-end mt-2 px-3">
        <v-btn text @click="back" class="btn">
          <v-icon color="black"> mdi-chevron-left</v-icon> {{ $t("Back") }}
        </v-btn>
        <v-btn text class="btn">
          <strong> {{ $t("Billingsupport") }} </strong>
        </v-btn>
        <v-spacer></v-spacer>
        <!-- <v-btn text @click="back" class="btn">
          <v-icon color="primary"> mdi-video</v-icon>
        </v-btn> -->
        <v-col cols="12">
          <p></p>
        </v-col>
      </v-row>
      <v-row class="d-flex flex-row justify-end mt-2 px-3">
        <!-- -->
        <div style="background: #f2f2f2">
          <v-alert
            dense
            style="background: #ccebe9; color: #000"
            type="info"
            colored-border
            v-if="lastMessage"
          >
            <span class="suportMessageStyle">
              {{ $t("Hi") }} <strong>{{ username }}!</strong>
              {{ $t("suportMessage") }}</span
            >
          </v-alert>
        </div>
      </v-row>
      <div
        ref="scrollList"
        class="sc-message-list"
        style="background: #f2f2f2"
        color="#0B1138"
      >
        <div class="chats">
          <template>
            <!-- {{ messages }} -->
            <div v-for="(message, index) in messages" :key="index">
              <v-row>
                <v-col cols="12">
                  <div>
                    <v-subheader v-if="message.date" inset>
                      {{ message.date }}
                    </v-subheader>
                    <v-divider v-if="message.date"></v-divider>
                  </div>
                  <v-row style="margin-bottom: -13%">
                    <v-col cols="1"> </v-col>
                    <v-col cols="11">
                      <span
                        class=""
                        v-if="message.author.id !== currentUser.id"
                        style="font-size: 10pt"
                      >
                        {{ getUserName(message.author.name) }}
                      </span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="1">
                      <v-avatar
                        size="26"
                        v-if="message.author.id !== currentUser.id"
                      >
                        <img :src="`/avatar.png`" alt="John" />
                      </v-avatar>
                    </v-col>
                    <v-col cols="11">
                      <v-row class="time">
                        <span
                          class="message-time-in"
                          v-if="
                            message.author.id === currentUser.id &&
                            index != messages.length - 1
                          "
                        >
                          <span style="margin-left: 72%; font-size: 9pt">
                            Eu</span
                          >
                        </span>
                        <span
                          class="message-time-in"
                          v-if="
                            message.author.id === currentUser.id &&
                            index === messages.length - 1
                          "
                        >
                          <span style="margin-left: 72%; font-size: 9pt">
                            Eu</span
                          ></span
                        >
                      </v-row>
                      <p
                        class="message"
                        :class="{
                          'message-out': message.author.id === currentUser.id,
                          'message-in': message.author.id !== currentUser.id,
                        }"
                      >
                        {{ message.body }}
                      </p>
                      <v-row class="time">
                        <span
                          class="message-time-out"
                          v-if="message.author.id !== currentUser.id"
                          >{{ message.time }}</span
                        >
                        <span
                          class="message-time-in"
                          v-if="
                            message.author.id === currentUser.id &&
                            index != messages.length - 1
                          "
                          >{{ message.time }}
                        </span>
                        <span
                          class="message-time-in"
                          v-if="
                            message.author.id === currentUser.id &&
                            index === messages.length - 1
                          "
                          >{{ sendFlag ? message.time : $t("sending") }}
                        </span>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </div>
          </template>
        </div>
      </div>
      <v-container style="background: #f2f2f2">
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="message"
              :prepend-inner-icon="icon"
              solo
              :disabled="loader"
              clear-icon="mdi-send"
              clearable
              :label="$t('typemessage')"
              type="text"
              @click:prepend="changeIcon"
              @click:append-outer="sendMessage"
              @click:clear="sendMessage"
              v-if="messages.length > 1"
            ></v-text-field>
            <v-text-field
              v-else
              v-model="message"
              :prepend-inner-icon="icon"
              dense
              solo
              clear-icon="mdi-send"
              clearable
              :label="$t('typemessage')"
              type="text"
              @click:prepend="changeIcon"
              @click:append-outer="sendMessage"
              @click:clear="sendMessage"
              disabled
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>
<script>
import { CHATS_QUERY_OUT } from "./../../graphql/Query";
import { SEND_MESSAGE_OUT, UPDATE_MESSAGE_OUT } from "./../../graphql/Mutation";
import { MESSAGE_SENT_SUBSCRIPTION } from "./../../graphql/subscription";
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  data: () => ({
    password: "Password",
    show: false,
    message: "",
    marker: true,
    iconIndex: 0,
    icons: [
      "mdi-emoticon",
      "mdi-emoticon-cool",
      "mdi-emoticon-dead",
      "mdi-emoticon-excited",
      "mdi-emoticon-happy",
      "mdi-emoticon-neutral",
      "mdi-emoticon-sad",
      "mdi-emoticon-tongue",
    ],
    messages: [],
    initMessage: true,
    checkSuportMessage: [],
    sendFlag: true,
    lastMessage: null,
    loader: true,
  }),
  apollo: {
    chats: {
      query: CHATS_QUERY_OUT,
      client: "apolloClientWhirelab",
      variables() {
        return { room: this.currentUser.id };
      },
      fetchPolicy: "no-cache",
      result({ data }) {
        if (data.chats) {
          console.log("chats", data.chats);
          this.messages = data.chats.messages;
          this.lastMessage =
            data.chats.messages[data.chats.messages.length - 1].date;
          if (this.messages.length > 1) {
            this.initMessage = false;
          }
          console.log("msg", this.messages);
          const messageDisplay = this.$refs.scrollList;
          messageDisplay.scrollTop = messageDisplay.scrollHeight;
        }
      },
    },
    $subscribe: {
      message: {
        query: MESSAGE_SENT_SUBSCRIPTION,
        client: "apolloClientWhirelab",
        result({ data }) {
          if (data.addChat.name == this.currentUser.id) {
            if (data.addChat.messages.author.id != this.currentUser.id) {
              console.log("addChat", data.addChat.messages);
              this.messages.push(data.addChat.messages);
              const messageDisplay = this.$refs.scrollList;
              messageDisplay.scrollTop = messageDisplay.scrollHeight;
            }
          }
        },
      },
    },
  },
  computed: {
    icon() {
      return this.icons[this.iconIndex];
    },
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
    username() {
      var name = this.currentUser.name.split(" ");
      return name[0];
    },

    x() {
      return this.messages;
    },
  },
  methods: {
    toggleMarker() {
      this.marker = !this.marker;
    },
    getUserName(name) {
      name = name.split(" ");
      return name[0] != name[name.length - 1]
        ? name[0] + " " + name[name.length - 1]
        : name[0];
    },
    async sendMessage() {
      let index = this.messages.findIndex(
        (m) => m.date == moment().format("LL")
      );
      var date = null;
      if (index > -1) {
        date = null;
      } else {
        date = moment().format("LL");
      }
      this.pushMessage();
      this.messages.push({
        body: this.message,
        time: moment().format("LT"),
        date: date,
        author: {
          id: this.currentUser.id,
          name: this.currentUser.name,
        },
        read: "1",
      });
      this.resetIcon();
      this.saveMessage();
      this.clearMessage();
    },
    clearMessage() {
      this.message = "";
    },
    resetIcon() {
      this.iconIndex = 0;
    },
    changeIcon() {
      this.iconIndex === this.icons.length - 1
        ? (this.iconIndex = 0)
        : this.iconIndex++;
    },
    back() {
      this.$emit("init");
    },
    close() {
      this.$emit("close");
    },

    onClickOutside(event, el) {
      console.log(event, el);
      this.close();
    },
    async pushMessage() {
      await this.$apollo
        .mutate({
          mutation: UPDATE_MESSAGE_OUT,
          client: "apolloClientWhirelab",
          variables: {
            room: this.currentUser.id,
            author: {
              id: this.currentUser.id,
              name: this.currentUser.name,
              photo: this.currentUser.photo,
              type: this.currentUser.type,
            },
          },
        })
        .then((res) => {
          if (res) {
            this.loader = false;
            const messageDisplay = this.$refs.scrollList;
            messageDisplay.scrollTop = messageDisplay.scrollHeight;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async saveMessage() {
      console.log("entrei");
      this.sendFlag = false;
      await this.$apollo
        .mutate({
          mutation: SEND_MESSAGE_OUT,
          client: "apolloClientWhirelab",
          variables: {
            nameRoom: this.currentUser.id,
            body: this.message,
            time: moment().format("LT"),
            date: moment().format("LL"),
            testID: null,
            to: this.messages[1].author,
            flag: "Suport",
            author: {
              id: this.currentUser.id,
              name: this.currentUser.name,
              photo: this.currentUser.photo,
              type: this.currentUser.type,
            },
            from: "Kenmare",
          },
        })
        .then((res) => {
          if (res.data.sendMessageOutSide.name) {
            console.log(res);
            this.sendFlag = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.pushMessage();
  },
};
</script>
<style scoped src="@/assets/css/chat_css/chatMessage.css">
.dfds {
  text-justify: distribute-all-lines;
}
</style>